import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/mvtest/charge',
  },

  {
    path: '/search',
    name: 'Search',
    component: () => import(/* webpackChunkName: "about" */ '../views/search')
  },
  {
    path: '/pay',
    name: 'Pay',
    component: () => import(/* webpackChunkName: "about" */ '../views/payPlan')
  },
  {
    path: '/mvtest/charge',
    name: 'GetCode',
    component: () => import(/* webpackChunkName: "about" */ '../views/getcode')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

export default router
