import Vue from 'vue'
import { Search } from 'vant'
import { Popup } from 'vant'
import { Cell, CellGroup } from 'vant'
import { Overlay } from 'vant'
import { Button } from 'vant'
import { Icon } from 'vant'
import { Toast } from 'vant'
import { Dialog } from 'vant'
import { Stepper } from 'vant'

// 全局注册
Vue.use(Dialog)
Vue.use(Toast)
Vue.use(Icon)
Vue.use(Button)
Vue.use(Overlay)
Vue.use(Cell)
Vue.use(Popup)
Vue.use(Search)
Vue.use(Stepper)